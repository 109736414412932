// @flow
import React from "react";
import { css } from "aphrodite";
import { Images, AppStyles } from "../../../theme";
import styles from "./CBTSecStyles";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { Button } from "react-bootstrap";
export default function CBTSecView(props) {
  return (
    <section
      id="cbt"
      className={`pt-5 mt-5 ${css([
        // styles.marginBottom,
        styles.CBTSec,
        // AppStyles.pxy_12,
      ])}`}
    >
      <div className={`container `}>
        <div className={css(styles.CBTInnerCont)}>
          <div className={css(styles.CBTTextCont)}>
            <div className={css(styles.cbtText)}>
            <h2 className={css(styles.CBTHeading)}>
            Tailored Counselling Services for Your Well-Being
              </h2>
              Your mental health deserves personalized care. Our tailored counselling services are designed to address your unique needs, providing a safe space to navigate challenges and build emotional resilience. Whether you’re dealing with stress, anxiety, relationships, or life transitions, our expert counselors are here to help.

            <br />
            <br />We offer a variety of flexible options, including face-to-face, online, telephone, and live chat sessions, ensuring support is always accessible. Using evidence-based approaches like CBT, EMDR, and solution-focused therapy, we guide you toward practical solutions and personal growth.

            <br />
            <br />Experience the transformative power of individualized counselling and take the first step toward a healthier, more balanced life. Your journey to well-being begins with us. Reach out today!
            </div>
          </div>
          <div className={css(styles.CBTInerContTwo)}>
            <img src={Images.cbt_imgThree} className={css(styles.cbtImgOne)} />
            </div>
          </div>
          {/* New Text Added1 */}

          <div className={css(styles.textHead)} style={{ marginBottom: "20px" }}>
          Benefits of Hiring Our Expert Therapists
        </div>
        
        <h3 className={css(styles.addText)}>
            <p className={css(styles.pText)}>Personalized Treatment Plans </p> Expert therapists craft tailored strategies to address your unique challenges, ensuring effective and results-driven care. This personalized approach helps achieve long-term mental well-being. <br />

            <p className={css(styles.pText)}>
            Access to Proven Techniques
            </p>

            Experienced therapists use evidence-based methods like CBT, mindfulness, or EMDR, delivering solutions backed by research. These approaches accelerate progress and build resilience. <br />

            <p className={css(styles.pText)}>Confidential and Safe Space</p>
            An expert therapist provides a judgment-free environment to explore your emotions. This sense of safety fosters open dialogue and helps you achieve clarity and healing.

          </h3>

          {/* New Text Added1 */}

          {/* New Text Added2 */}
        <div>
          <h2 className={css(styles.etextHead)}>
          How It Works
          </h2>
        </div>

        <h3 className={css(styles.addText)}>
            <p className={css(styles.pText)}>Initial Consultation 
              </p> Begin with a confidential consultation where you can openly share your concerns, challenges, and therapy goals. This step helps us understand your unique needs. <br />

            <p className={css(styles.pText)}>
            Customized Therapy Plan
            </p>

            Our expert counselors design a tailored plan just for you. Whether you prefer face-to-face sessions, online meetings, telephone counseling, or live chat, we adapt to your preferences. <br />

            <p className={css(styles.pText)}>Your Journey to Wellness </p>
            Start your sessions with our compassionate team. We focus on equipping you with practical tools, coping techniques, and emotional resilience to navigate life’s challenges.
            <br />
            <br />
            <p> 
            Our process ensures personalized care, flexibility, and consistent support at every step, empowering you to take control of your mental well-being with confidence and ease. Begin your journey to a healthier, happier you today!
            </p>
          </h3>

        {/* New Text Added2 */}

        </div>
    </section>
  );
}
