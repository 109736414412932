// @flow
import React, { useRef } from "react";
import { css } from "aphrodite";
import { NavLink } from "react-router-dom";
import styles from "./WebHeroSecStyles";
import { Images, AppStyles, Colors } from "../../../theme";
import { HEADER_HEIGHT, ROUTES } from "../../../constants";
import { Button } from "react-bootstrap";

export default function WebHeroSecView(props) {
  const scrollToFunction = () => {
    const elem = document.getElementById("cbt");
    if (elem) {
      window.scrollTo({
        top: elem.offsetTop - HEADER_HEIGHT,
        behavior: "smooth",
      });
    } else {
      window.location.href = `#cbt`;
    }
  };
  return (
    <section className={` ${css([styles.heroSectionInner, AppStyles.pxy_12])}`}>
      <div className={`container`}>
        <div className={css(styles.cardBox)}>
          <h1 className={css(styles.cardHeading)}>
          Empowering Lives Through Expert Counselling Services
          </h1>

          <div className={css(styles.boxText)}>
            
Discover personalized counselling services designed to support your mental well-being. From stress management to personal growth, our expert counsellors provide compassionate care tailored to your needs.
          </div>

          <NavLink to={ROUTES.BOOKING}>
            <Button className={css([styles.bookNowBtn])}>Book Appointment</Button>
          </NavLink>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              onClick={scrollToFunction}
              className={css(styles.arrowIconCont)}
            >
              <img src={Images.arrow_down} className={css(styles.arrowIcon)} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
